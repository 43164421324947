.ui.action.input.search-input {
  background: white;
  border: 1px solid @teal;
  border-right: none;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 25px !important;
  box-shadow: 0em 0em 0em 0em transparent inset;

  & > input {
    font-weight: 700;
    border: none;
    border-radius: 25px !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ui.button.search {
    border-radius: 25px;
    background-color: white;
    color: @grey;
    border: 1px solid @teal;
  }
}