.ui.label {
  &.language {
    padding: @languageVerticalPadding @languageHorizontalPadding;
    color: @teal;
    font-weight: @languageFontWeight;
    margin-right: @languageMarginRight;
    position: relative;
    bottom: @languageLift;
  }

  &.keyword-subjects, &.alt-identifiers {
    font-size: 0.75rem;
    text-wrap: nowrap;
    border-radius: 6px;
    padding: 0.5rem;
    background-color: transparent;

    .subject-link {
      color: @black;
    }
  }

  &.alt-identifiers {
    padding-left: 0;
    
    .detail-identifier-type {
      padding: 0.5rem;
      background-color: @teal;
      color: @white;
      border-radius: 6px 0 0 6px;
    }
  }

  &.external-subjects {
    font-size: 1.1rem;
    margin-right: 1rem;
    background-color: transparent;
    a {
      color: @teal;
    }
  }

  &.frontpage-subtitle {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    border-radius: 0.25rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
