.ui.horizontal.list.inline {
    display: inline;
}

.ui.link.items.search-listing-item-group {
    .search-listing-item {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .row {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

.ui.list.subjects {
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;

    & > .item {
        margin-left: 0;
    }
}