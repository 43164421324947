.column.results-list.item-main {
    flex: 1;
}
    
.column.results-list.item-side {
    flex: 0 0 8rem;
}
 
.ui.grid > .row {
    &.detail-search-menu {
        margin-bottom: calc(39px - 1rem);
    }

    & > .column.main-record-content {
        @media screen and (min-width: @tabletBreakpoint) and (max-width: 1300px) {
            margin-bottom: 1rem;
        }
    }

    & > .column.detail-back-to-search-link {
        display: flex;
        align-items: center;

        .left.chevron.icon {
            display: inline-block;
            margin-right: 40px;
        }
    }
    
    &.result-options {
        padding-bottom: 0;
    }

    &.facets-and-search-listing {
        padding-top: 0.5rem;
    }
}