.main.container {
  margin-top: 43px;
  margin-bottom: 49px;
}

.ui.container.cover-page {
  justify-content: center;
}

.ui.container.page-subheader-outer {
  background-color: @highlightHeaderColor;
}
